import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ContactUsStyle } from "./style";
import logo from "../../assests/logo.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Handle form submission
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbw9FEba4nnP9jGtofAyMlIl-pdzxWMoUWeXgpOor4o5tRifkj7_FglkMXQnPGkJmRmr_Q/exec",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(data),
        }
      );
  
      const responseData = await response.json();
      if (response.ok) {
        toast.success(responseData.msg || "Form submitted successfully!");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        toast.error(responseData.error || "There was an error submitting the form.");
      }
    } catch (error) {
      toast.error("There was an error submitting the form.");
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <ContactUsStyle>
      <div className="contact_us_2">
        <div className="responsive-container-block big-container">
          <div className="blueBG"></div>
          <div className="responsive-container-block container">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="container-block form-wrapper">
                <span className="top-heading">
                  <img src={logo} alt="Logo" />
                </span>
                <div className="form-box">
                  <div className="input-field">
                    <label>
                      Name <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Name"
                      {...register("Name", {
                        required: "Name is required",
                        pattern: {
                          value: /^[A-Za-z\s]+$/, 
                          message:
                            "Name cannot contain numbers or special characters",
                        },
                      })}
                    />
                    {errors.Name && (
                      <p className="error-message">{errors.Name.message}</p>
                    )}
                  </div>
                  <div className="input-field">
                    <label>
                      Email <span className="required">*</span>
                    </label>
                    <input
                      type="email"
                      placeholder="Email"
                      {...register("Email", {
                        required: "Email is required",
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                          message: "Invalid email format",
                        },
                      })}
                    />
                    {errors.Email && (
                      <p className="error-message">{errors.Email.message}</p>
                    )}
                  </div>
                </div>

                <div className="form-box">
                  <div className="input-field">
                    <label>
                      Phone <span className="required">*</span>
                    </label>
                    <input
                      type="tel"
                      placeholder="Phone"
                      {...register("Phone", {
                        required: "Phone number is required",
                        minLength: {
                          value: 10,
                          message: "Phone number must be at least 10 digits",
                        },
                        maxLength: {
                          value: 15,
                          message: "Phone number cannot exceed 15 digits",
                        },
                      })}
                    />
                    {errors.Phone && (
                      <p className="error-message">{errors.Phone.message}</p>
                    )}
                  </div>
                  <div className="input-field">
                    <label>State</label>
                    <input
                      type="text"
                      placeholder="State"
                      {...register("State", {
                        pattern: {
                          value: /^[A-Za-z\s]+$/, 
                          message: "State cannot contain numbers",
                        },
                      })}
                    />
                    {errors.State && (
                      <p className="error-message">{errors.State.message}</p>
                    )}
                  </div>
                </div>

                <div className="form-box">
                  <div className="input-field">
                    <label>City</label>
                    <input
                      type="text"
                      placeholder="City"
                      {...register("City")}
                    />
                  </div>
                  <div className="input-field">
                    <label>
                      Zip Code <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Zip Code"
                      {...register("ZipCode", {
                        required: "Zip Code is required",
                        pattern: {
                          value: /^\d+$/,
                          message: "Zip Code must be a valid number",
                        },
                      })}
                    />
                    {errors.ZipCode && (
                      <p className="error-message">{errors.ZipCode.message}</p>
                    )}
                  </div>
                </div>

                <div className="btns btn-submit">
                  <button type="submit" disabled={loading}>
                    {loading ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </ContactUsStyle>
  );
};

export default ContactUs;
