import styled from "styled-components";

export const ContactUsStyle = styled.div`
  .contact_us_2 .container-block {
    min-height: 75px;
    width: 100%;
    padding: 10px;
    display: block;
  }

  .contact_us_2 .responsive-container-block {
    min-height: 75px;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .contact_us_2 .responsive-container-block.big-container {
    position: relative;
    height: auto;
  }

  .contact_us_2 .responsive-container-block.container {
    position: static;
    min-height: 75px;
    z-index: 2;
    max-width: 800px;
    margin-top: 60px;
    margin-right: auto;
    margin-bottom: 30px;
    margin-left: auto;
  }
  .top-heading{
    display: flex;
    justify-content: center;
    gap: 8px;
    margin: 10px 0px;

    img{
      width: 180px;
    }
  }

  .contact_us_2 .container-block.form-wrapper {
    background-color: white;
    max-width: auto;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 20px 7px;
    border-radius: 6px;
    margin: 0px auto;
    padding: 25px;
  }

  .contact_us_2 .blueBG {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 0px;
    height: 250px;
    background-color: #060489;
  }

  .form-box {
    display: flex;
    align-items: center;
    gap: 30px;
    width: 100%;
  }
  .input-field {
    width: 50%;
  }
  label {
    padding-bottom: 10px;
    color: #2c3e56;
 
  }

  h2 {
    text-align: center;
    font-size: 28px;
    margin-bottom: 20px;
  }

  .input-field {
    margin: 5px 0px;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    input {
      width: 100%;
      padding: 10px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 16px;
      margin-top: 5px;
      outline: none;
    }
    .error-message {
      color: red;
      font-size: 12px;
      margin-bottom: 10px;
    }
  }
  .btn-submit {
    display: flex;
    justify-content: center;
  }

  .btn-submit button {
    width: 50%;
    margin-top: 20px;
    padding: 10px;
    background: #eb0d0d;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 900px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    /* &:hover {
      background-color: #0056b3;
    } */
  }
  .btn-back {
    display: flex;
    justify-content: center;
  }

  .btn-back button {
    width: 50%;
    margin-top: 20px;
    padding: 10px;
    background: #d91e18;
    color: black;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    /* &:hover {
      color;
    } */
  }
  .required {
    color: red; 
    margin-left: 1px; 
}

  @media (max-width: 900px) {
    .input-field {
      width: 100%;
    }

    .form-box {
      flex-direction: column;
      gap: 10px;
    }
  }
`;
