// In this file, you will be putting the styling which you are going to use globally in your application which leads to the reusability of the styling

import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: var(--Nunito-family);
    }

    :root{      
        --Nunito-family:'Nunito', sans-serif;
    }

    @font-face {
        font-family: "Nunito";
        src: url("/fonts/Nunito/Nunito-VariableFont_wght.ttf") format("truetype");
    }   
    
    .container {
    max-width: 1200px;
    margin: 0px auto;
    padding: 20px 40px;
  }
  @media screen and (max-width: 576px) {
    .container {
        padding: 10px 20px;
    }
    }

`;
