import styled, { keyframes } from "styled-components";
export const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const zoomIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const NavStyle = styled.div`
  .navigation {
    background-color: white;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    /* flex-wrap: wrap; */
  }
  .logo {
    width: 170px;
    height: auto;
    @media screen and (max-width: 576px) {
      width: 140px;
    }
  }
  .right button {
    white-space: nowrap;
    cursor: pointer;
    border: none;
    padding: 10px 20px;
    background: #d91e18;
    border-radius: 7px;
    color: white;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    text-align: center;
    @media screen and (max-width: 576px) {
      padding: 7px 14px;
    }
  }

  @media screen and (max-width: 768px) {
  }
`;

export const SectionOneStyle = styled.div`
  .background-clr {
    background-color: #060489;
    padding: 30px 0px;
  }
  .section-one {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    width: 100%;
  }

  .image-side {
    width: 50%;
    animation: ${zoomIn} 1s ease-out;
  }

  .image-side img {
    width: 100%;
    height: 400px;
    border-radius: 20px;
    object-fit: cover;
    animation: ${zoomIn} 1s ease-in-out;
  }
  @media screen and (max-width: 1068px) {
    .section-one {
      flex-wrap: wrap;
    }

    .image-side {
      width: 100%;
      animation: ${zoomIn} 1s ease-out;
    }

    .image-side img {
      width: 100%;
      border-radius: 10px;
      object-fit: cover;
    }
  }
  .left-content{
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .sec-one-logo {
  }
  .sec-one-logo img {
    padding: 10px;
    width: 300px;
    height: 70px;
    background-color: white;
    border-radius: 10px;
    @media screen and (max-width: 576px) {
      width: 250px;
    }
  }

  .top-description {
    font-size: 26px;
    font-weight: 600;
    line-height: 27.28px;
    color: white;
    animation: ${fadeInUp} 1.2s ease-in-out;
  }

  .first-form {
    animation: ${fadeInUp} 1.3s ease-in-out;
  }
  .first-form button {
    padding: 12px 15px;
    background: #d91e18;
    border-radius: 7px;
    cursor: pointer;
    border: none;
    color: white;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: -0.02em;
    text-align: center;
    @media screen and (max-width: 576px) {
      padding: 8px;
      font-size: 16px;
    }
  }

  .bottom-text {
    color: white;
    font-size: 22px;
    font-weight: 500;
    line-height: 27.28px;
    text-align: left;
    animation: ${fadeInUp} 1.5s ease-in-out;
  }
  .bold-plus {
    font-weight: 900;
  }
`;

export const SectionTwoStyle = styled.div`
  background-color: white;
  padding: 30px 0px;

  .section-two-heading {
    font-size: 48px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: -0.02em;
    text-align: center;
    margin-bottom: 20px;
    color: #223043;
    animation: ${fadeInUp} 1s ease-in-out;
    @media screen and (max-width: 576px) {
      font-size: 36px;
    }
  }
  .section-two-content {
    margin: 30px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      gap: 20px;
    }
  }

  .content-text {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .verified-icon img {
    margin-top: 5px;
    width: 30px;
    height: 30px;
    @media screen and (max-width: 576px) {
      width: 25px;
      height: 25px;
    }
  }

  .content-text span {
    color: #223043;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.02em;
    animation: ${fadeInUp} 1.2s ease-in-out;
    @media screen and (max-width: 576px) {
      font-size: 16px;
    }
  }

  .section-two-btn {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .section-two-btn button {
    cursor: pointer;
    border: none;
    padding: 12px 30px;
    width: fit-content;
    color: white;
    font-weight: bold;
    background: #d91e18;
    font-size: 18px;
    border-radius: 7px;
    animation: ${fadeInUp} 1.3s ease-in-out;
    @media screen and (max-width: 576px) {
      padding: 10px 15px;
      font-size: 14px;
    }
  }
  .section-bottom-logo{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      width: 200px;
    }
  }
`;

export const SectionThreeStyle = styled.div`
  .background-clr {
    background-color: #060489;
    padding: 30px 0px;
  }
  .section-three {
    margin: 50px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    @media screen and (max-width: 1068px) {
      flex-wrap: wrap;
      gap: 20px;
      margin: 20px 0px;
    }
  }

  .section-three-img {
    animation: ${zoomIn} 1s ease-out;
  }

  .section-three-img img {
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
    animation: ${zoomIn} 1s ease-in-out;
    @media screen and (max-width: 1068px) {
      border-radius: 10px;
    }
  }
  .section-three-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .three-main-heading {
    color: white;
    font-size: 44px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: -0.02em;
    animation: ${fadeInUp} 1.2s ease-in-out;
    @media screen and (max-width: 576px) {
      font-size: 24px;
    }
  }

  .three-form button {
    cursor: pointer;
    border: none;
    padding: 15px 30px;
    width: fit-content;
    color: white;
    background: #d91e18;
    font-size: 18px;
    font-weight: bold;
    border-radius: 7px;
    animation: ${fadeInUp} 1.3s ease-in-out;
    @media screen and (max-width: 1068px) {
      padding: 10px 15px;
      font-size: 14px;
    }
  }

  .three-bottom-text {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 7px;
    font-size: 22px;
    font-weight: 600;
    line-height: 27.28px;
    color: white;

    animation: ${fadeInUp} 1.5s ease-in-out;
    @media screen and (max-width: 1068px) {
    }
  }



  .sec-three-logo img {
    width: 170px;
    padding: 2px 5px;
    background-color: white;
    border-radius: 3px;
    margin-top: 9px;
  }
`;

export const SectionFourStyle = styled.div`
  background-color: white;
  padding: 30px 0px;
  .sec-heading {
    font-size: 48px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: -0.02em;
    text-align: center;
    animation: ${fadeInUp} 1s ease-in-out;
    @media screen and (max-width: 576px) {
      font-size: 36px;
    }
  }
  .sec-cards {
    margin: 50px 0px;
    display: flex;
    justify-content: center;
    gap: 50px;
    text-align: center;
    @media screen and (max-width: 992px) {
      flex-wrap: wrap;
      gap: 25px;
    }
  }
  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 20px;
    padding: 30px;
    width: 280px;
    background: #0d99ff;
    border-radius: 10px;
    @media screen and (max-width: 576px) {
      font-size: 20px;
    }
  }
  .card-heading {
    color: white;
    font-size: 30px;
    font-weight: 700;
    white-space: nowrap;
    animation: ${fadeInUp} 1.2s ease-in-out;
    @media screen and (max-width: 576px) {
      font-size: 24px;
    }
  }
  .card-description {
    font-size: 22px;
    font-weight: 600;
    color: white;
    animation: ${fadeInUp} 1.5s ease-in-out;
    @media screen and (max-width: 576px) {
      font-size: 16px;
    }
  }
  .sec-card-bottom {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
    color: #2c3e56;
    margin-top: 30px;
  }
  .card-form-btn {
    margin-top: 30px;
  }
  .card-form-btn button {
    cursor: pointer;
    border: none;
    padding: 12px 25px;
    width: fit-content;
    color: white;
    background: #d91e18;
    font-size: 18px;
    font-weight: bold;
    line-height: 27px;
    letter-spacing: -0.02em;
    text-align: center;
    border-radius: 7px;
    animation: ${fadeInUp} 1.3s ease-in-out;
    @media screen and (max-width: 576px) {
      padding: 10px 15px;
      font-size: 14px;
    }
  }
  .section-bottom-logo{
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      width: 250px;
      @media screen and (max-width: 576px) {
  width: 200px;
    }
    }
  }
`;

export const SectionFiveStyle = styled.div`
  background-color: #060489;
  padding: 30px 0px;
  position: relative;

  .purple_backdrop {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    background-color: #060489;
    width: 100%;
    height: 100%;
    z-index: 10;
    opacity: 0;
    transition: 0.4s all;
    pointer-events: none;
  }

  .purple_backdrop.visible {
    opacity: 0.8;
    pointer-events: auto;
  }

  .sec-five {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    z-index: 20;
    @media screen and (max-width: 1068px) {
      flex-wrap: wrap;
      gap: 20px;
    }
  }

  .five-left-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 20;
  }

  .five-main-heading {
    font-size: 48px;
    font-weight: 900;
    line-height: 50px;
    letter-spacing: -0.02em;
    text-align: left;
    color: white;
    margin-bottom: 5px;
    animation: ${fadeInUp} 1s ease-in-out;
  }

  .underline {
    border-bottom: 4px solid #fff;
  }

  .image-side {
    width: 50%;
    animation: ${zoomIn} 1s ease-out;
    @media screen and (max-width: 1068px) {
      width: 100%;
    }
    z-index: 20;
  }

  .image-side img {
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
  }

  .top-description1,
  .top-description2 {
    font-size: 22px;
    font-weight: 600;
    line-height: 27.28px;
    text-align: left;
    color: white;
    z-index: 20;
  }

  .top-description1 {
    margin-top: 15px;
  }

  .top-description2 {
    margin: 10px 0px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
  }

  .sec-five-logo img {
    margin-right:3px;
    width: 170px;
    padding: 2px 5px;
    background-color: white;
    border-radius: 3px;
    margin-top: 5px;
  }



  .last-form-btn button {
    cursor: pointer;
    border: none;
    padding: 12px 30px;
    width: fit-content;
    color: white;
    background: #d91e18;
    font-size: 18px;
    font-weight: bold;
    line-height: 27px;
    letter-spacing: -0.02em;
    text-align: center;
    border-radius: 7px;
    margin-top: 15px;
    z-index: 20; 
    @media screen and (max-width: 576px) {
      padding: 10px 10px;
      font-size: 14px;
    }
  }
`;

export const HomeStyle = styled.div`
  a {
    text-decoration: none;
  }
`;
