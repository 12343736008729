import React from "react";
import Home from "./container/home";
import ContactUs from "./container/contact-us";
import { Route, Routes } from "react-router-dom";

const App = () => {
  return (
    <div>
    <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/contact-us" element={<ContactUs/>} />
      </Routes>
    </div>
  );
};

export default App;
